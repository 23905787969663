// @flow
import React from 'react'
import Prism from 'prismjs'
import { StaticQuery, graphql } from 'gatsby'
import { Dropdown } from '@conceptsauce/react-stack'

import Editor from 'src/components/editor'
import Console from 'src/components/console'

type State = {
  selectedIndex: number,
}

class MultiExample extends React.PureComponent<null, State> {
  state = {
    selectedIndex: 0,
  }

  // $FlowFixMe
  code = React.createRef()

  componentDidMount() {
    if (this.code && this.code.current)
      Prism.highlightElement(this.code.current)
  }

  componentDidUpdate() {
    if (this.code && this.code.current)
      Prism.highlightElement(this.code.current)
  }

  handleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    // $FlowFixMe
    this.setState({ selectedIndex: e.target.selectedIndex })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allExampleCode {
            allExampleCode {
              edges {
                node {
                  name
                  description
                  example
                }
              }
            }
          }
        `}
        render={({ allExampleCode: { edges } }) => {
          if (!edges.length) return null
          const { selectedIndex } = this.state
          const { name, example, description } = edges[selectedIndex].node
          return (
            <>
              <h3>Other</h3>

              <Dropdown defaultValue={name} my={3} onChange={this.handleChange}>
                {edges.map(({ node: { name } }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </Dropdown>

              {description && <p>{description}</p>}

              <div className="gatsby-highlight" data-language="javascript">
                <pre className="language-javascript">
                  <code ref={this.code} className="language-javascript">
                    {example}
                  </code>
                </pre>
              </div>

              <Editor example={name} />

              <Console example={name} />
            </>
          )
        }}
      />
    )
  }
}

export default MultiExample
